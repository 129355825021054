import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

type DataProps = Record<string, unknown>;

const NotFoundPage: FC<PageProps<DataProps>> = () => (
  <Layout>
    <SEO title="404: Píchlé kolo - Půjčovna kol BeRider" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
